<template>
  <div id="swap">
    <Header :current="currentTab"/>
    <div class="main-wrapper text-center mx-auto" v-if="state == '1'">
        <h5>Swap</h5>
        <Alert v-if="showError" :error="systemMsg" :isError="isError"/>
        <div class="d-flex justify-content-center">
            <div class="wrapper-one mx-auto">
                <h6 for="" style="margin-bottom:0;">{{currency.one}}</h6>
                <div class="input-group mx-auto">
                    <div class="input-group-prepend">
                        <button class="btn btn-toggler" type="button" v-on:click="toggleCountryListOne">
                        <img class="default-token" :src="require('../assets/tokens/' + currency.one + '.png' )">
                        <img src="../assets/dropdown-white.png" alt="" class="toggler-arrow">
                        </button>
                    </div>
                </div>
                <div class="currency-list" v-if="showCurrencyListOne">
                <div class="row">
                    <div class="col-sm-6" v-for="c in currencyList" :key="c.id" v-on:click="selectFirstCurrency(c)" :class="{ 'hide-token': hideToken }">
                    <div>
                        <img class="currency-list-img" :src="require('../assets/tokens/' + c.currency + '.png' )">
                    </div>
                    <div class="currency-name"><span> {{ c.currency }}</span></div>
                    </div> 
                </div>
                </div>
            </div>
            <img src="../assets/swap arrow.png" alt="" class="swap-arrow">
            <div class="wrapper-two mx-auto">
                <h6 for="" style="margin-bottom:0;">{{currency.two}}</h6>
                <div class="input-group mx-auto">
                    <div class="input-group-prepend">
                        <button class="btn btn-toggler" type="button" v-on:click="toggleCountryListTwo">
                        <img class="default-token" :src="require('../assets/tokens/' + currency.two + '.png' )">
                        <img src="../assets/dropdown-white.png" alt="" class="toggler-arrow">
                        </button>
                    </div>
                </div>
                <h6 for="" style="margin-bottom:0;display:block;" class="text-white">{{currency.two}}</h6>
                <div class="currency-list" v-if="showCurrencyListTwo">
                  <div class="row">
                      <div class="col-sm-6" v-for="c in currencyList" :key="c.id" v-on:click="selectSecondCurrency(c)">
                      <div>
                          <img class="currency-list-img" :src="require('../assets/tokens/' + c.currency + '.png' )">
                      </div>
                      <div class="currency-name"><span> {{ c.currency }}</span></div>
                      </div> 
                  </div>
                </div>
            </div>
        </div>
        <button class="btn btn-connect mt-5" @click="confirmSwap">Confirm Swap</button>
    </div>
    <div class="main-wrapper text-center mx-auto" v-if="state == '2'">
        <h5>Swap</h5>
        <Alert v-if="showError" :error="systemMsg" :isError="isError"/>
        <div class="d-flex justify-content-center mx-auto amount-wrapper">
            <div>
                <img class="default-token" :class="{ 'bec-token': currency.one == 'BEC' }" style="width: 50px; height:50px;" :src="require('../assets/tokens/' + currency.one + '.png' )">
                <h6 class="mb-0">Balance:</h6>
                <p>{{ formatAmount(balance.one) }}</p>
            </div>
            <div class="form-wrapper">
                <div class="ml-auto text-right">
                    <p>Amount to deposit:</p>
                </div>
                <input type="text" class="form-control" @keypress="isNumber" @keyup="calculate" v-model="values.one">
            </div>
        </div>
        <div class="text-left error-wrapper mx-auto">
            <p class="error-msg">{{ fieldErrors.sourceAmount }}</p>
        </div>
        <!-- <div class="d-flex justify-content-center mx-auto amount-wrapper">
            <div>
                <img class="default-token" :class="{ 'bec-token': currency.one == 'BEC' }" style="width: 50px; height:50px;" :src="require('../assets/tokens/' + currency.two + '.png' )">
                <h6 class="mb-0">Balance:</h6>
                <p>{{ formatAmount(balance.two) }}</p>
            </div>
            <div class="form-wrapper">
                <div class="ml-auto text-right">
                    <p>Amount to deposit:</p>
                </div>
                <input type="text" class="form-control" readonly v-model="values.two">
            </div>
        </div>
        <div class="text-left error-wrapper mx-auto">
            <p class="error-msg">{{ fieldErrors.targetAmount }}</p>
        </div> -->
        <h6 class="mt-3 mb-3">Current Price</h6>
        <h6 class="error-msg" v-if="!hasRates">{{ fieldErrors.price }}</h6>
        <h6 v-if="hasRates">{{ formatAmount(rates.one) }} {{ currency.one }} per {{ formatAmount(rates.two) }} {{ currency.two }}</h6>
        <div class="d-flex justify-content-around">
            <button class="btn btn-cancel" @click="goBack">Back</button>
            <button class="btn btn-connect" @click="swap">Approve</button>
        </div>
    </div>
    <div class="main-wrapper text-center mx-auto" v-if="state == '3'">
        <Alert :error="systemMsg" :isError="isError"/>
        <div class="details mt-3 d-flex justify-content-center">
            <div class="image-stack-wrapper">
                <div class="image-stack">
                    <div class="image-stack__item image-stack__item--top">
                        <img class="token-swapped-one" :src="'../assets/tokens/' + currency.one + '.png'" alt="">
                    </div>
                    <div class="image-stack__item image-stack__item--bottom">
                        <img class="token-swapped-two" :src="'../assets/tokens/' + currency.two + '.png'" alt="">
                    </div>
                </div>
            </div>
            <h6 class="mt-3">{{ formatAmount(values.one) }} {{ currency.one }} → {{ formatAmount(values.two) }} {{ currency.two }}</h6>
        </div>
        <button class="btn btn-connect" @click="done">DONE</button>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Alert from '../components/Alert.vue'
import numeral from 'numeral'

export default {
  components: {
    Header,
    Alert,
  },
  data: function() {
    return {
      currentTab: 'swap',
      state: '1',
      currencyList: [
        { currency: 'BEC' },
        { currency: 'BIXIE' },
        { currency: 'BLC' },
        { currency: 'BLX' },
        { currency: 'CXC' },
        { currency: 'EPX' },
        { currency: 'GWX' },
        { currency: 'JCC' },
        { currency: 'MUX' },
        { currency: 'NCF' },
        { currency: 'NFC' },
        { currency: 'NIN' },
        { currency: 'PIN' },
        { currency: 'RPCOIN' },
        { currency: 'STAR' },
        { currency: 'TST' },
        { currency: 'USDX' },
        { currency: 'VAZ' },
        { currency: 'XBTC' },
        { currency: 'XCOIN' },
        { currency: 'XETH' },
      ],
      filteredCurrencyList: [],
      currency: {
        one: 'BEC',
        two: 'BIXIE'
      },
      values: {
        one: '',
        two: ''
      },
      isReadOnly: {
        one: false,
        two: false
      },
      currency: {
        one: 'BEC',
        two: 'BIXIE'
      },
      rates: {
          one: '',
          two: '',
      },
      values: {
          one: '',
          two: ''
      },
      isReadOnly: {
          one: false,
          two: false
      },
      balance: {
          one: '',
          two: ''
      },
      showCurrencyListOne: false,
      showCurrencyListTwo: false,
      systemMsg: "",
      showError: false,
      fieldErrors: {
          sourceAmount: '',
          targetAmount: '',
          price: 'No price yet.'
      },
      hideCurrency: false,
      showCurrencyListOne: false,
      showCurrencyListTwo: false,
      isError: true,
      hasRates: false,
      hideToken: false
    }
  },
  methods: {
    confirmSwap() {
        this.state = 2
        this.values.one = ''
        this.values.two = ''
        this.clearErrors();
        this.getFunds();
    },
    goBack() {
        this.state = '1'
    },
    done() {
      location.reload();
    },
    getPrice() {
      const params = {
        fromCurrency: this.currency.one,
        toCurrency: this.currency.two,
        sourceAmount: this.values.one,
      }

      const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/pub/liquiditypool/swap/price',
        data,
      };
      this.axios(options)
        .then((response) => {
          this.hasRates = true,
          this.rates.one = response.data.sourceAmount
          this.rates.two = response.data.targetAmount
        })
        .catch((error) => {
          this.hasRates = false
          this.fieldErrors.price = error.response.data.msgText
          if(this.fieldErrors.price == 'Unexpected System Error') {
            this.fieldErrors.price == 'Value should be greater than 0'
          }
        });
    },
    swap() {
      const params = {
        fromCurrency: this.currency.one,
        toCurrency: this.currency.two,
        sourceAmount: this.values.one,
      }

      const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');


      const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data,
          url: '/cx/liquiditypool/swap',
      };
      this.axios(options)
          .then((response) => {
              this.state = '3'
              this.isError = false
              this.showError = true
              this.systemMsg = 'Swap successful.'
          })
          .catch((error) => {
              if(error.response.data.fieldErrors) {
                  error.response.data.fieldErrors.forEach((err, index) => {
                      switch(err.field) {
                          case 'sourceAmount':
                              this.fieldErrors.sourceAmount = err.defaultMessage
                              break;
                          case 'targetAmount':
                              this.fieldErrors.targetAmount = err.defaultMessage
                              break;
                      }
                  })
              } else {
                  this.isError = true
                  this.showError = true
                  this.systemMsg = error.response.data.msgText
              }
          });
    },
    toggleCountryListOne() {
      this.showCurrencyListOne = !this.showCurrencyListOne
      this.showCurrencyListTwo = false
    },
    selectFirstCurrency(selected) {
      this.showCurrencyListOne = false
      this.currency.one = selected.currency
      this.values.one = ''
      this.values.two = ''
      this.isReadOnly.one = false
      this.isReadOnly.two = false
      this.removeSimilarValues();
    },
    toggleCountryListTwo() {
      this.showCurrencyListTwo = !this.showCurrencyListTwo
    },
    getFunds: function() {
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/cx/funds',
      };

      this.axios(options)
          .then((response) => { 
              response.data.forEach((value, index) => {
                  if(value.currency == this.currency.one) {
                      this.balance.one = value.availableBalance
                      console.log(formatAmount(this.balance.one))
                  } else {
                      this.balance.one = '0'
                  }
              })
          }).catch((err) => {
              
          })
    },
    selectSecondCurrency(selected) {
      this.showCurrencyListTwo = false
      this.currency.two = selected.currency
      this.values.one = ''
      this.values.two = ''
      this.isReadOnly.one = false
      this.isReadOnly.two = false
      this.removeSimilarValues();
      this.filterArray();
    },
    removeSimilarValues() {
      this.currencyList.forEach((value, index) => {
        if(value.currency != this.currency.one) {
          if(value.currency != this.currency.two) {
            return value.currency
          }
        }
      })
    },
    filterArray() {
      this.currencyList.filter(this.removeSimilarValues);
      console.log(this.currencyList)
    },
    formatAmount: function(num){
      return numeral(num).format('0,0.00');
    },
    calculate() {
      this.clearErrors();
      this.values.two = this.values.one
      this.getPrice();
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
        console.log(charCode)
      } else {
        return true;
      }
    },
    clearErrors() {
      this.fieldErrors.sourceAmount = ''
      this.fieldErrors.targetAmount = ''
      this.showError = false
      this.systemMsg = ''
    }
  },
  mounted() {
    this.removeSimilarValues();
    this.filterArray();
  }
}
</script>

<style scoped lang="scss">
  #swap {
    background-image: url(../assets/bg.png);
    padding: 25px;
    color:white;
    height: 100vh;
  }

  .main-wrapper {
      margin-top:100px;
      width:500px;
      padding: 80px 5px;
      background-color: #121b25;
      border-radius:5px;
  }

  .btn-toggler {
      background-color:transparent;
      color:black;
  }

  .toggler-arrow {
      margin-left:0.5em;
  }

  .input-group {
      width:30em;
  }

  .wrapper-one {
    position: relative;
    padding-top: 20px;
    padding-bottom: 30px;
    width:100px;
    .input-group-prepend {
    height: 38px;
    .btn-toggler {
        border-radius:5px;
        padding: 5px;
        background-color:transparent;
        height:100px;
    }
    }
    .default-token {
        width: 5em;
        margin-right:0.25em;
    }
    .currency-list {
    position: absolute;
    background: #ccc;
    color: #333;
    top: 120px;
    padding: 0.5em;
    height: 13em;
    overflow: auto;
    z-index: 3;
    width:500px;
    .row {
        margin: 0 !important;
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
          &:hover {
              background: darken(#ccc, 10%);
          }
          .currency-name {
              margin-left: 0.5em;
          }
          span {
              font-size: 0.9em;
          }
        }
        .hide-token {
          display:none;
        }
      }
    }
  }

  .wrapper-two {
    position: relative;
    padding-top: 20px;
    padding-bottom: 30px;
    width:100px;
    .input-group-prepend {
    height: 38px;
    .btn-toggler {
        border-radius:5px;
        padding: 5px;
        background-color:transparent;
        height:100px;
    }
    }
    .default-token {
        width: 5em;
        margin-right:0.25em;
    }
    .currency-list {
    position: absolute;
    background: #ccc;
    color: #333;
    top: 120px;
    padding: 0.5em;
    height: 13em;
    overflow: auto;
    z-index: 3;
    width:500px;
    .row {
        margin: 0 !important;
        .col-sm-6 {
        display: flex;
        padding: 0.5em;
        align-items: center;
        cursor: pointer;
        &:hover {
            background: darken(#ccc, 10%);
        }
        .currency-name {
            margin-left: 0.5em;
        }

        span {
            font-size: 0.9em;
        }
        }
      }
    }
  }

  .currency-list-img {
    width: 33px;
  }

  .btn-cancel {
    margin-top:20px;
    color:white;
    border: 1px white solid;
    width: 200px;
  }

  .btn-connect {
    margin-top:20px;
    color:white;
    background-image: linear-gradient(to right, #f1cf61, #db9b32);
    width: 200px;
  }

  .swap-arrow {
    width:2em;
    height:2em;
    margin-top:60px;
  }

  .min-max-wrapper {
    width: 250px;
    .btn-min-max {
        background-color: white;
        color: black;
    }
  }

  .amount-wrapper {
    width: 400px;
    padding:10px;
    background-color:white;
    border-radius:10px;
    color:black;
    margin: 10px 0px;
    .form-wrapper {
        margin-left:3em;
        .form-control {
            border: 1px black solid;
        }
    }
    .bec-token {
        border:1px black solid;
        border-radius:25px;
    }
  }

  .error-wrapper {
      width: 400px;
  }

  .image-stack-wrapper {
        background-color:transparent;
        .image-stack {
            display: grid;
            position: relative; // imperative for the overlapping to work
            grid-template-columns: repeat(12, 1fr);
            width:80px;
            .image-stack__item--top {
                grid-column: 4 / -1;
                grid-column: 1 / span 8;
                grid-row: 1;
            }

            .image-stack__item--bottom {
                grid-row: 1;
                grid-column: 4 / -1;
                padding-top: 20%; 
                z-index: 1; // slightly arbitrary, keeps proportion once resized
                // tells the browser to make this image on top
            }

            img {
            width: 40px;
            display: block;
            }
        }
    }

    .image-stack-wrapper {
        background-color:transparent;
        .image-stack {
            display: grid;
            position: relative; // imperative for the overlapping to work
            grid-template-columns: repeat(12, 1fr);
            width:80px;
            .image-stack__item--top {
                grid-column: 4 / -1;
                grid-column: 1 / span 8;
                grid-row: 1;
            }

            .image-stack__item--bottom {
                grid-row: 1;
                grid-column: 4 / -1;
                padding-top: 20%; 
                z-index: 1; // slightly arbitrary, keeps proportion once resized
                // tells the browser to make this image on top
            }

            img {
            width: 40px;
            display: block;
            }
        }
    }
</style>